import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

import 'locales/i18n';

const GeneralConditionPage = ({ location }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={location}>
      <SEO
        title={t('cgu.title')}
        lang={i18n.language || 'fr'}
        metaTitle={t('meta.title')}
        metaDescription={t('meta.description')}
      />

      <div className="row my-6">
        <div className="col-md-6 col-sm-8 mx-auto">
          <h1>{t('cgu.title')}</h1>
          <div className="general-conditions">
            <h4 style={{ marginTop: '40px' }}>1.Définitions</h4>
            <p>
              <strong>Client</strong> : particulier / société / collectivité
              publique désirant bénéficier des produits et services de
              l’application HITCH.
            </p>
            <p>
              <strong>Coach</strong> : particulier / société désirant bénéficier
              des produits et services de l’application HITCH, tels que coach
              sportif, association sportives, fitness, etc.
            </p>
            <p>
              <strong>Contrat</strong> : contrat de vente et d'achat des
              Produits et Services, y compris toutes les transactions
              informatiques passées en ligne sur le site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> ou sur
              tous smartphone au moyen de l’application HITCH, contrat pour
              l’utilisation de la plateforme HITCH, et tout autre contrat entre
              HITCH et le Client et/ou le Coach.
            </p>
            <p>
              <strong>HITCH</strong> : désigne le titulaire de tous les droits
              sur l’application du nom de HITCH, soit M. Hicham DOUICHI, à
              LAUSANNE, respectivement la société Dopamine Company SA, à
              LAUSANNE
            </p>
            <p>
              <strong>Plateforme</strong> : le site Internet et/ou l’application
              HITCH.
            </p>
            <p>
              <strong>Produits et Services</strong> : tout objet du Contrat,
              soit notamment les produits et services de l’application HITCH
              auxquels s’appliquent les présentes conditions générales.
            </p>
            <h4 style={{ marginTop: '40px' }}>2.Champ d'application</h4>
            <p>
              Les présentes conditions générales s'appliquent à titre exclusif à
              tous les Contrats passés entre HITCH et le Client, et/ou le Coach
              ainsi qu'aux rapports précontractuels. Elles s’appliquent à toute
              utilisation des Produits et Services pour faire partie intégrante
              des rapports précontractuels et contractuels entre HITCH, les
              Clients, et les Coaches. Les termes commençant par une majuscule
              sans être définis par ailleurs, doivent être compris tels que
              définis dans les présentes conditions générales.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              3.Contrat, Produits et Services
            </h4>
            <p>
              3.1.Le Contrat conclu entre le Client et HITCH se fonde sur les
              présentes conditions générales d'utilisation. Le Contrat a pour
              objet l'utilisation gratuite ou payante des Produits et Services
              proposés sous le nom de HITCH sur notre site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> ou par
              le biais de notre application logicielle de la Plateforme. Le
              Client qui souhaite utiliser l’application HITCH par le biais
              d'une application mobile peut télécharger gratuitement
              l’application sur le site Internet{' '}
              <a href="http://www.wearehitch.com">www.wearehitch.com</a>. HITCH
              et la Plateforme ne garantissent pas que l’application HITCH soit
              disponible et fonctionnelle pour tout type de téléphone portable
              et/ou système d’exploitation, ce sans aucune responsabilité.
              <br />
              <br />
              3.2.Les éléments figurant sur le site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> et/ou
              sur l’application smartphone HITCH ne constituent pas une offre
              mais un appel d'offre sans engagement. HITCH ne garantit ainsi pas
              les spécifications techniques, ni la disponibilité des Produits et
              Services présentés. Les changements de Produits et Services et de
              prix sont réservés et possibles en tout temps à la discrétion de
              HITCH.
              <br />
              <br />
              3.3.Par son inscription sur la Plateforme et le téléchargement de
              l’application HITCH sur son téléphone portable, le Client peut
              bénéficier gratuitement des Produits et Services disponibles sur
              l’application HITCH. Pour ce faire, le Client doit uniquement
              télécharger l’application HITCH. Il doit être âgé de 18 (dix-huit)
              ans révolus, disposer de la pleine capacité juridique et être en
              bonne santé, ce qu’il garantit ici expressément.
              <br />
              <br />
              3.4.Les Produits et Services disponibles sur la Plateforme sont à
              ce jour les suivants:
              <ul>
                <li>
                  Mise en relation avec les Coaches de la Plateforme pour
                  réserver et bénéficier des cours payants (ci-après: Services
                  de coaching)
                </li>
                <li>
                  Mise en ligne sur la Plateforme de l’agenda du Coach pour
                  s’inscrire aux cours payants et système de paiement des cours
                  par un prestataire de services tiers (STRIPE).
                </li>
              </ul>
              3.5.Dans l’hypothèse où la Plateforme mettrait en place un futur
              et potentiel système de chat en ligne permettant au Coach et au
              Client de dialoguer directement sur l’application HITCH, il est
              précisé ce qui suit tant et aussi longtemps que le potentiel futur
              système de chat en ligne n’est pas disponible sur la Plateforme,
              le numéro de téléphone professionnel du Coach sera indiqué pour
              les Clients sur la Plateforme afin que ces derniers puissent
              communiquer. Cela étant, si le potentiel futur système de chat est
              mis en ligne sur la Plateforme, le Client s’engage alors à ne plus
              communiquer avec le/s Coach/es que par ledit système de chat de la
              Plateforme.
              <br />
              <br />
              3.6.Les Services de coaching mettent en relations le Client et le
              Coach qui travailleront alors ensemble pour fixer les objectifs à
              réaliser. Le Client et le Coach peuvent notamment interagir par la
              mise en commun d’un agenda pour fixer les séances de travail, par
              des discussions intégrées à l’application, par courriel, appels
              téléphoniques, etc. à convenir d’un commun accord entre le Client
              et le Coach. HITCH n’est aucunement partie à cette relation
              contractuelle entre le Client et le Coach.
              <br />
              <br />
              3.7.En utilisant l’application HITCH, le Client s’engage à
              utiliser l’application, ainsi que les Produits et Services
              conformément aux présentes conditions générales, et conformément à
              toutes les réglementations applicables tant au lieu de situation
              de HITCH, soit en SUISSE, que dans le pays de résidence et/ou de
              domicile du Client, et/ou du Coach, que dans le pays dans lequel
              l’application, les Produits et Services sont concrètement
              utilisés.
              <br />
              <br />
              3.8.Le Client, et /ou le Coach s’engagent notamment mais non
              exhaustivement à ne pas utiliser l’application HITCH à des fins
              contraires aux lois citées sous chiffre 3.7 et à s’abstenir de a)
              contourner ou mettre en échec de quelque manière que ce soit les
              mesures techniques mises en œuvre par HITCH ou par ses
              fournisseurs de services ou tout autre tiers visant à protéger le
              site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> et/ou
              sur l’application smartphone «HITCH» ou à b) accéder au site
              Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> et/ou à
              l’application smartphone HITCH par l’utilisation d’un mécanisme
              autre que celui autorisé par les présentes conditions générales.
              HITCH ne peut pas être tenu pour responsable de tout éventuel
              dommage ou responsabilité résultant d’une telle utilisation, ce
              qui inclut également les éventuelles transactions non autorisées
              ou souhaitées qui pourraient en résulter.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              4.Santé: exclusion de responsabilité
            </h4>
            <p>
              4.1.L’application HITCH est une plateforme dédiée au sport et au
              bien-être dont l’objectif est d’aider les Clients à atteindre
              leurs objectifs en matière de forme et/ou de sport en les mettant
              en relation avec un ou plusieurs Coach/s. Ces Produits et Services
              ne constituent aucunement un produit ou service médical ou de
              santé au sens large. Les Produits et Services ne peuvent pas
              remplacer des consultations médicales dispensées par des
              professionnels dûment autorisés.
              <br />
              <br />
              4.2.Le Client certifie ici être en bonne santé et ne pas avoir de
              contrindication médicale ou autre pour bénéficier des Produits et
              Services. En tout état de cause, le Client est seul responsable de
              consulter un médecin avant d’utiliser les Produits et Services. Si
              le Client et/ou le Coach pense/nt se trouver dans une situation
              d’urgence médicale ou de risque pour la santé et/ou l’intégrité
              physique du Client, il incombe à eux seuls de contacter
              immédiatement un médecin et/ou les services d’urgences médicales.
              HITCH décline toute responsabilité de ce fait.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              5.Coaches et réservation des Services de coaching
            </h4>
            <p>
              5.1.Les Coaches ne sont pas des employés ni des auxiliaires de
              HITCH mais des prestataires de services totalement indépendants
              qui agissent sous leur seule et unique responsabilité. Ainsi, les
              Coaches fixent librement et individuellement le prix de leurs
              Services de coaching.
              <br />
              <br />
              5.2.Les Coaches ne sont pas formés par HITCH ni n’ont pu faire
              l’objet de vérifications quant à leurs compétences et
              qualifications. S’agissant des Services de coaching, un lien
              contractuel existe uniquement entre le Client et le Coach, HITCH
              ne saurait ainsi être tenu pour responsable de tout éventuelle
              blessure ou autre atteinte à la santé ou à la personne et /ou
              dommages matériel subis par le Client et/ou le Coach.
              <br />
              <br />
              5.3.Les Services de coaching sont fournis sans aucune garantie de
              quelque nature que ce soit. HITCH ne saurait être tenu pour
              responsable des propos et éventuels engagements de résultats pris
              par les Coaches, de l’exactitude, de la fiabilité, disponibilité
              et efficacités des conseils et autres exercices prodigués par les
              Coaches, que ce soit directement ou indirectement. HITCH ne peut
              pas être tenu pour responsable des éventuels problèmes de santé
              qui pourraient survenir directement ou indirectement du fait de la
              relation contractuelle existant entre le Coach et le Client.
              <br />
              <br />
              Le Coach exerçant en qualité d’indépendant, les disponibilités de
              ce dernier peuvent varier, voire cesser. Aucune prétention en
              dommages-intérêts ne peut être déduite contre HITCH de ce fait.
              HITCH se réserve de plus le droit de retirer le/les Coach/es des
              programmes disponibles sur l’application, ce sans avoir à envoyer
              d’avis préalable au Client ni lui devoir une quelconque
              indemnisation de ce fait. Si le Coach n’est plus disponible, HITCH
              pourra naturellement mettre le Client en contact avec un autre
              Coach de l’application.
              <br />
              <br />
              5.4.Dans le cadre de leurs relations, HITCH rappelle aux Clients
              et aux Coaches qu’ils leur incombent de se comporter de manière
              polie, appropriée et professionnelle. Tout comportement
              inapproprié d’un Client ou d’un Coach doit être signalé à HITCH à
              l’adresse suivante
              <a href="mailto:help@wearehitch.com">help@wearehitch.com</a>. En
              cas de comportement gravement inapproprié ou si une situation
              problématique se reproduit, HITCH se réserve le droit
              discrétionnaire d’exclure immédiatement un Client ou un Coach de
              l’application, ce sans que ce dernier n’ait droit au moindre
              dédommagement.
              <br />
              <br />
              5.5.Les réservations des cours payants par le Client se font
              directement par l’application HITCH: le jour de la réservation
              du/des cours par le Client, ce dernier confirme sa réservation de
              manière définitive. Le jour de la réservation, la Plateforme HITCH
              demandera alors au Client d’indiquer ses coordonnées bancaires
              complètes (lors de la première réservation sur la Plateforme).
              72h00 avant le cours payant, le montant à débiter sera bloqué sur
              la carte bancaire du Client comme garantie et 24h00 avant le cours
              payant, le montant sera débité de la carte bancaire du Client. Si
              le Client réserve un cours moins de 24h00 avant la date et l’heure
              de la séance, la carte bancaire sera alors directement débitée par
              la Plateforme.
              <br />
              <br />
              5.6.En cas d’annulation ou de déplacement d’un cours réservé, le
              Coach et le Client conviendront, dans toute la mesure du possible
              d’un cours de remplacement. Si cela n’est pas possible, HITCH peut
              proposer au Client de recourir aux Services de coaching d’un autre
              Coach de l’application. Dans l’hypothèse où le potentiel futur
              système de chat serait disponible sur la Plateforme, alors le
              Coach et le Client s’engagent à ne communiquer que via ledit
              système de chat.
              <br />
              <br />
              5.7.En cas d’annulation ou de déplacement d’un cours pour lequel
              le paiement a été débité, le Client reconnaît et accepte
              expressément que HITCH n’assume aucune responsabilité financière
              ou autre, que l’annulation soit le fait du Client ou du Coach. Si
              l’annulation est le fait du Coach et qu’elle n’est pas justifiée
              (par ex. par une maladie), HITCH pourra, à bien plaire et sans
              reconnaissance de responsabilité, proposer un cours de
              remplacement au Client avec un autre Coach de la Plateforme.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              6.Tarifs, moyens de paiement et prestataire de services tiers
            </h4>
            <p>
              6.1.Les tarifs des Produits et Services sont indiqués sur le site
              Internet{' '}
              <a href="http://www.wearehitch.com">www.wearehitch.com</a>
              et/ou sur l’application smartphone HITCH. Les tarifs des Services
              de coaching sont fixés par le Coach.
              <br />
              <br />
              6.2.Le Client reconnaît et accepte expressément que les tarifs des
              Produits et des Services peuvent être modifiés en tout temps à la
              discrétion des Coaches et de HITCH, notamment afin de répercuter
              les modifications tarifaires des prestataires de services tiers.
              <br />
              <br />
              6.3.Le Client s’engage expressément à ne pas convenir avec le
              Coach d’un tarif inférieur ou supérieur à celui annoncé à HITCH et
              indiqué sur la Plateforme. En cas de violation de cet engagement
              contractuel, HITCH pourra résilier avec effet immédiat le présent
              Contrat et exclure, avec effet immédiat le Client et le Coach de
              la Plateforme, ce sans qu’aucune indemnisation ne leur soit due de
              ce fait.
              <br />
              <br />
              6.4.Le Client est informé et accepte que HITCH recoure aux
              services d’un Prestataire de services tiers (STRIPE) s’agissant
              des modalités d’encaissement des paiements des Clients. Dans ce
              contexte, le Client accepte d’emblée de se conformer aux
              conditions requises par le prestataire de services tiers. Le
              Client déclare expressément avoir pris connaissance et accepter
              les conditions générales de STRIPE librement disponibles sur le
              site Internet «stripe.com/docs/connect» qui font alors parties
              intégrantes du présent Contrat.
              <br />
              <br />
              6.5.En indiquant le moyen de paiement à la Plateforme, le Client
              garantit expressément être autorisé à utiliser ledit moyen de
              paiement et détenir tous les droits y afférents pour autoriser
              valablement le prestataire de moyens de paiements tiers à débiter
              la somme due, ce qui inclut toutes les éventuelles taxes ou autres
              coûts et frais engendrés. Si le moyen de paiement n’est pas
              valable ou refusé pour quelque motif que ce soit par le
              prestataire de paiement tiers choisi par HITCH, les Produits et
              Services peuvent être suspendus ou annulés et il incombe au seul
              Client de résoudre le problème pour que les prestations puissent
              reprendre. Le Client est rendu attentif et accepte que le montant
              prélevé sur les Services de coaching puisse varier, notamment en
              raison de modifications des taxes ou taux de change applicables.
              Ils autorisent d’ores et déjà le prestataire de paiement tiers
              choisi par HITCH à débiter le moyen de paiement choisi de
              l’ensemble de la somme due, y compris les fluctuations possibles.
            </p>
            <h4 style={{ marginTop: '40px' }}>7.Fin du contrat et exclusion</h4>
            <p>
              7.1.Le Client est en droit de supprimer, à tout moment et sans
              avoir à justifier de motifs, son compte utilisateur et par là
              même, de mettre fin à son contrat d'utilisateur. Il suffit au
              Client de contacter l’adresse suivante
              <a href="mailto:help@wearehitch.com">help@wearehitch.com</a> afin
              qu’il soit procédé à la suppression du compte dans les meilleurs
              délais. Le Client sait et accepte expressément qu’après la
              suppression de son compte utilisateur, tous les contenus et les
              résultats d'entraînement auxquels il/elle avait accès seront
              supprimés ou seront susceptibles de l'être par HITCH.
              <br />
              <br />
              7.2.Dans l’hypothèse où le Client ne respecterait pas les clauses
              des présentes conditions générales, HITCH pourra, après une unique
              mise en demeure adressée par simple courriel au Client, exclure le
              Client de l’application en cas de nouvelle violation des
              conditions générales. En cas de justes motifs, HITCH pourra
              exclure immédiatement et sans mise en demeure le Client. La
              résiliation du contrat du Client ne donne doit à aucune
              indemnisation de ce dernier.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              8.Confidentialité et traitement des données
            </h4>
            <p>
              8.1.Les Produits et Services, ainsi que toute utilisation de
              l’application HITCH sont strictement personnels et ne peuvent pas
              être utilisés par une autre personne que le Client. Le Client
              s’engage à ne pas autoriser un tiers à utiliser l’application, les
              Produits et Services ni divulguer son mot de passe. Toute
              utilisation abusive doit être immédiatement annoncée à HITCH sur
              le site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a>.
              <br />
              <br />
              8.2.Le Client est conscient et accepte que son utilisation de
              l’application HITCH, ainsi que des Produits et Services exigent
              qu’il fournisse des informations personnelles à HITCH,
              respectivement au Coach, et à propos de ses données de paiement au
              Prestataire de services tiers (STRIPE). Même si HITCH met en œuvre
              des mesures permettant de protéger les informations précitées, le
              Client est seul responsable du maintien de la confidentialité de
              ses données et du maintien en sa possession de son smartphone ou
              autre appareil informatique permettant d’accéder à l’application
              HITCH. En cas de perte du smartphone ou autre appareil
              informatique permettant d’accéder à l’application HITCH, il est
              possible de suspendre les Produits et Services sur le site
              Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a>.
              <br />
              <br />
              8.3.HITCH s'engage à respecter dans la mesure du possible la
              confidentialité des informations fournies par le Client. La
              Politique de confidentialité de HITCH est consultable dans sa
              version actuellement en vigueur sur le site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a>. Cette
              dernière définit et prévoit, en particulier, l'étendue des données
              à caractère personnel visibles par les autres utilisateurs et les
              possibilités dont dispose le Client pour contrôler leur
              transmission aux autres utilisateurs. Dans les paramètres du
              profil, le Client a la possibilité de déterminer quelles
              informations (profil d’utilisateur, informations d’entraînement,
              publications, lieux d’entraînement, etc.) sont visibles («Compte
              privé»).
            </p>
            <h4 style={{ marginTop: '40px' }}>9.Propriété et licence</h4>
            <p>
              9.1.Le site Internet et/ou l’application de HITCH et tous les
              droits s’y rapportant sont et demeureront la propriété exclusive
              de HITCH. Ni le présent Contrat ni l’utilisation des Produits et
              des Services n’ont pour effet de transmettre ou d’octroyer un
              quelque droit que ce soit : (a) sur les Produits et les Services
              ou en lien avec ces derniers, à l’exception de la licence limitée
              accordée ci-dessous ; ou (b) d’utiliser ou de faire référence, de
              quelque manière que ce soit, à la raison sociale, aux logos, noms
              de produits et de services, marques de HITCH.
              <br />
              <br />
              9.2.Sous réserve du respect des clauses du présent Contrat et des
              Conditions générale, HITCH accorde au Client une licence limitée,
              non exclusive, sans droit de sous-licence, révocable et non
              cessible : (a) d’accès et d’utilisation de l’application HITCH sur
              son dispositif personnel pour ce qui a trait uniquement à votre
              utilisation des Produits et Services ; et (b) d’accès et
              d’utilisation de tout contenu, information et matériel y afférent
              pouvant être mis à sa disposition dans le cadre des Produits et
              Services. Tous droits non expressément accordés par le présent
              Contrat sont la propriété exclusive de HITCH.
            </p>
            <h4 style={{ marginTop: '40px' }}>10.Dispositions finales</h4>
            <p>
              10.1.HITCH se réserve le droit de compléter et de modifier les
              présentes Conditions générales avec effet pour l’avenir, si cela
              est nécessaire en raison de la modification des conditions
              légales, administratives ou techniques, et si ces modifications
              sont exigibles de bonne foi en prenant en considération les
              intérêts des Clients. Les modifications des présentes Conditions
              générales seront annoncées sur le site Internet
              <a href="http://www.wearehitch.com">www.wearehitch.com</a> au plus
              tard 1 (une) semaine avant l'entrée en vigueur prévue de la
              nouvelle version des Conditions générales. Sauf opposition écrite
              du Client, les nouvelles Conditions générales seront réputées
              approuvées. En cas d'opposition, HITCH se réserve expressément le
              droit de procéder à une résiliation immédiate du contrat.
              <br />
              <br />
              10.2.Si le présent accord est vicié dans certaines de ses clauses,
              seules ces clauses seront frappées de nullité. Le reste de
              l’accord demeurera efficace.
              <br />
              <br />
              10.3.Droit applicable : le DROIT INTERNE SUISSE s'applique
              exclusivement.
              <br />
              <br />
              10.4.<strong>Prorogation de for</strong> : les parties conviennent
              de soumettre tout litige relatif au Contrat et à son
              interprétation aux tribunaux ordinaires de{' '}
              <strong>LAUSANNE (SUISSE)</strong>. La présente prorogation de for
              est <strong>EXCLUSIVE</strong>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

GeneralConditionPage.propTypes = {
  location: PropTypes.object.isRequired,
};
GeneralConditionPage.defaultProps = {};

export default GeneralConditionPage;
